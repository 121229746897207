























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Admin extends Vue {
  @Prop() public id!: number;

  public $q: any;
  public $route: any;
}
